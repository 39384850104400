import Head from 'next/head'
import axios from 'axios'
import Swal from 'sweetalert2'
import Container from '../components/container'
import { Carousel } from 'react-responsive-carousel'
import 'emoji-mart/css/emoji-mart.css'

import {
  Box,
  Text,
  Button,
  Grid,
  Flex,
  Heading,
  Link as ChakraLink,
  Image,
  useClipboard,
  useToast,
  PseudoBox,
  Collapse
} from '@chakra-ui/core'
import { Emoji } from 'emoji-mart'
import useSWR, { mutate } from 'swr'
import NextLink from 'next/link'
import Navbar from '../components/navbar'
import { FaFacebookMessenger } from 'react-icons/fa'
import BlogItems from '../components/blog/items'
import { Testimonial } from '../components/testimonial'
import { useUser } from '../hooks/use-user'
import useCookieConsent from '../hooks/use-cookie-consent'
import useChatra from '../hooks/use-chatra'
import { StageOne } from './book/[...slug]'
import { useState } from 'react'
import { BlockPicker } from 'react-color'

// TODO COOKIE CONSENT

const Fake = ({
  bgColor, textColor, borderColor,
  backPanelColor, dayColor, headerColor,
  oldDaysColor, weekDaysColor, buttonColor
}) => {
  const [date, setDate] = useState(new Date())

  const onDateClick = (date, e) => {
    setDate(date)
  }

  const currentDate = new Date()

  const dates = [
    new Date().setHours(currentDate.getHours() + 1, 0),
    new Date().setHours(currentDate.getHours() + 2, 0),
    new Date().setHours(currentDate.getHours() + 3, 0),
    new Date().setHours(currentDate.getHours() + 4, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24).setHours(currentDate.getHours() + 1, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24).setHours(currentDate.getHours() + 3, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24).setHours(currentDate.getHours() + 8, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24).setHours(currentDate.getHours() + 9, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 2).setHours(currentDate.getHours() + 2, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 2).setHours(currentDate.getHours() + 6, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 4).setHours(currentDate.getHours() + 1, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 6).setHours(currentDate.getHours() + 3, 0),
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 9).setHours(currentDate.getHours() + 2, 0)
  ]

  return (
    <Flex 
      background={bgColor && bgColor === 'transparent' ? bgColor : '#' + (bgColor || 'f1f2f3')}
      flexDirection='column' marginTop='32px' paddingTop='32px' paddingBottom='32px' width='100%'>
      <Box>
        <Flex justifyContent='center'>
          <Text
            fontWeight='700'
            marginTop={['20px', '0', '0', '0']}
            width='100%'
            textAlign='center'
            color={'#' + (headerColor || '#000000')}
            fontSize={['1.75rem', '1.75rem', '2.25rem', '2.5rem']}
            lineHeight='2.8125rem'
          >
            Select the time of the call
          </Text>
        </Flex>
      </Box>
      <StageOne
        key='stage-test'
        eventType={{
          title: 'Meeting with the best customer',
          background: {
            url: '/mock.png'
          },
          description: 'Introduction',
          duration: 1000 * 60 * 15,
          author: {
            displayName: 'Author display name'
          }
        }}
        author={{
          displayName: 'Ingvar Kloch'
        }}
        date={date}
        onDateClick={onDateClick}
        onMonthChange={() => {}}
        includeTimes={dates}
        includeDates={dates}
        backPanelColor={backPanelColor}
        textColor={textColor}
        dayColor={dayColor}
        borderColor={borderColor}
        oldDaysColor={oldDaysColor}
        weekDaysColor={weekDaysColor}
        buttonColor={buttonColor}
      />
      <Flex marginTop='20px' justifyContent='center'>
        <NextLink href='/dashboard' textDecoration='none'>
          <Button
            isDisabled={false}
            onClick={() => {}}
            padding='10px 20px'
            minWidth='303px'
            fontSize='1.125rem'
            background={
                  `#${buttonColor || '4065FF'}`
                }
            fontWeight='600'
            lineHeight='1.75rem'
            height='48px'
            color={
                  `#${backPanelColor || 'ffffff'}`
                }
            borderRadius='6px'
            _hover={{ background: '#0031ff' }}
          >
            Choose time
          </Button>
        </NextLink>
      </Flex>
    </Flex>
  )
}

export default function Home () {
  useChatra()
  useUser({ redirectIfFound: '/events' })

  const [selectedItem, setSelectedItem] = useState(0)

  const [collapse, setCollapse] = useState(false)
  const [activeColor, setActiveColor] = useState('')

  const [bgColor, setBgColor] = useState('F9F9FF')
  const [textColor, setTextColor] = useState('131a29')
  const [borderColor, setBorderColor] = useState('dbdbdb')
  const [backPanelColor, setBackPanelColor] = useState('ffffff')
  const [oldDaysColor, setOldDaysColor] = useState('c4c4c4')
  const [dayColor, setDayColor] = useState('F5F5F5')
  const [headerColor, setHeaderColor] = useState('000000')
  const [weekDaysColor, setWeekDaysColor] = useState('c4c4c4')
  const [buttonColor, setButtonColor] = useState('4065ff')

  const colorSetMap = {
    bgColor: setBgColor,
    textColor: setTextColor,
    borderColor: setBorderColor,
    backPanelColor: setBackPanelColor,
    oldDaysColor: setOldDaysColor,
    dayColor: setDayColor,
    headerColor: setHeaderColor,
    weekDaysColor: setWeekDaysColor,
    buttonColor: setButtonColor
  }

  const onColorChange = (color) => colorSetMap[activeColor](color === 'transparent' ? color : color.hex.replace('#', ''))

  const colorMap = {
    bgColor,
    textColor,
    borderColor,
    backPanelColor,
    oldDaysColor,
    dayColor,
    headerColor,
    weekDaysColor,
    buttonColor
  }

  return (
    <div>
      <Navbar />
      <Head>
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <Container
        mt={['98px', '37px', '37px', '37px']}
        maxW='1040px'
        paddingTop={['0', '0', '0', '0']}
        paddingBottom={['40px', '60px', '80px', '100px']}
      >
        <Flex direction='column' alignItems='center'>
          <Text
            fontSize={['2.5rem', '3.75rem', '5rem', '6rem']}
            letterSpacing='tight'
            fontWeight='bold'
            mb='17px'
            color='black'
            lineHeight='1.2'
            textAlign={['left', 'center', 'center', 'center']}
          >
            Keep your <Box as='span' color='purpleBlue'>meeting context</Box> in one place
          </Text>
          <Text
            fontSize='1.25rem'
            textAlign={['left', 'center', 'center', 'center']}
            mb='50px'
          >
            Hapen is reborn into the next-generation meeting management solution.
            <Box
              as='br'
              display={['none', 'inline-block', 'inline-block', 'inline-block']}
            />
            Share scheduling pages with your customers.
            <Box
              as='br'
              display={['none', 'inline-block', 'inline-block', 'inline-block']}
            />
            And we will send notification{' '}
            <ChakraLink href='#event-ways' color='purpleBlue'>
              where it is convenient for them.
            </ChakraLink>
          </Text>
          <Image src='/static/homepage.jpg' alt='hapen' title='hapen' />
          <Flex marginTop='50px' justifyContent='center'>
            <NextLink href='/dashboard' textDecoration='none'>
              <Button
                textDecoration='none'
                padding={['10px 20px', '10px 20px', '10px 40px', '10px 40px']}
                fontSize={['1.125rem', '1.125rem', '1.5rem', '1.5rem']}
                background='#4065FF'
                fontWeight='600'
                lineHeight='1.75rem'
                height={['48px', '48px', '70px', '70px']}
                color='#ffffff'
                borderRadius='6px'
                _hover={{ background: '#0031ff', textDecoration: 'none' }}
              >
                Create a booking page
              </Button>
            </NextLink>
          </Flex>
        </Flex>
      </Container>
      <Box background='#F9F9FF'>
        <Container
          id='use-cases'
          maxW='1040px'
          paddingTop={['40px', '60px', '65px', '65px']}
          paddingBottom={['40px', '45px', '45px', '45px']}
        >
          <Flex
            alignItems='center'
            direction='column'
            justifyContent='center'
            mb='60px'
          >
            <Heading
              as='h2'
              fontSize={['2.25rem', '3rem', '3rem', '4rem']}
              textAlign={['center', 'center', 'center', 'center']}
              mb='12px'
            >
              Your embeddable {' '}
              <Box as='span' color='cardBlue'>
                booking pages
              </Box>
            </Heading>
            <Text fontSize='1.25rem' textAlign='center'>
              Create an individual page for each type of meeting you have.
              <br />
              We support the deep customize colors of the pages. Your designer will be happy 🙏
            </Text>
          </Flex>
          <Box>
            <Flex justifyContent='center' flexWrap='wrap'>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('bgColor')

                  if (activeColor === 'bgColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'bgColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'bgColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Background
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('textColor')

                  if (activeColor === 'textColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'textColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'textColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Text
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('borderColor')

                  if (activeColor === 'borderColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'borderColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'borderColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Border
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('backPanelColor')

                  if (activeColor === 'backPanelColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'backPanelColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'backPanelColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Back panel
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('dayColor')

                  if (activeColor === 'dayColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'dayColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'dayColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Day
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('headerColor')

                  if (activeColor === 'headerColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'headerColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'headerColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Header
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('oldDaysColor')

                  if (activeColor === 'oldDaysColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'oldDaysColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'oldDaysColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Past days
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('weekDaysColor')

                  if (activeColor === 'weekDaysColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'weekDaysColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'weekDaysColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Week days
              </Button>
              <Button
                onClick={() => {
                  setCollapse(true)
                  setActiveColor('buttonColor')

                  if (activeColor === 'buttonColor') setCollapse(false)
                }}
                _hover={{
                  background: '#4065FF',
                  color: '#fff'
                }}
                background={activeColor === 'buttonColor' ? '#4065FF' : '#e7e7e7'}
                color={activeColor === 'buttonColor' ? '#fff' : '#333'} borderRadius='7px' margin='4px'
              >
                Button
              </Button>
            </Flex>
            <Flex marginTop='20px' justifyContent='center'>
              <Collapse isOpen={collapse}>
                <BlockPicker
                  color={colorMap[activeColor]}
                  onChange={onColorChange}
                />
              {activeColor === 'bgColor' && (
                <Button
                  onClick={() => {
                    setCollapse(false)
                    
                    onColorChange('transparent')
                  }}
                  background="#4065FF"
                  borderRadius="7px"
                  margin="4px"
                  marginTop='20px'
                  color="#fff"
                >
                  Set transparent
                </Button>
              )}
              </Collapse>
            </Flex>
            <Fake
              bgColor={bgColor} textColor={textColor}
              borderColor={borderColor} backPanelColor={backPanelColor}
              dayColor={dayColor} headerColor={headerColor}
              oldDaysColor={oldDaysColor} weekDaysColor={weekDaysColor} buttonColor={buttonColor}
            />
          </Box>
          {/* <Box>
            <Box
              background='white'
              borderRadius='6px'
              boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
              padding={[
                '20px 20px 0 20px',
                '30px 30px 0 30px',
                '30px 30px 0 30px',
                '30px 30px 0 30px'
              ]}
              display='flex'
              flexDirection={['column', 'row', 'row', 'row']}
            >
              <Box width={['100%', '40%', '40%', '40%']} mr='10%'>
                <Heading
                  margin='0'
                  marginBottom='12px'
                  as='h3'
                  color='black'
                  fontSize={['1.75rem', '2.5rem', '2.5rem', '2.5rem']}
                >
                  For your{' '}
                  <Box as='span' color='cardBlue'>
                    friends
                  </Box>
                </Heading>
                <Text
                  fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                  opacity='0.6'
                >
                  Notify your friends about the release of a cool new movie
                </Text>
              </Box>
              <Box width={['100%', '55%', '55%', '55%']}>
                <Image src='/friends15x.png' alt='friends' />
              </Box>
            </Box>
            <Flex mt='20px' direction={['column', 'row', 'row', 'row']}>
              <Box
                width={['100%', '59%', '59%', '59%']}
                mr={['0px', '20px', '20px', '20px']}
              >
                <Box
                  background='white'
                  borderRadius='6px'
                  boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                  padding={[
                    '20px 20px 0 20px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px'
                  ]}
                >
                  <Heading
                    margin='0'
                    marginBottom='8px'
                    as='h3'
                    color='black'
                    fontSize={[
                      '1.75rem',
                      '2.1875rem',
                      '2.1875rem',
                      '2.1875rem'
                    ]}
                  >
                    For your{' '}
                    <Box as='span' color='cardBlue'>
                      colleagues
                    </Box>
                  </Heading>
                  <Flex
                    justifyContent='space-between'
                    direction={['column', 'row', 'row', 'row']}
                  >
                    <Text
                      fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                      opacity='0.6'
                      width={['100%', '45%', '35%', '35%']}
                      mb={['0px', '35px', '0px', '0px']}
                      mr={['0px', '1%', '1%', '1%']}
                    >
                      Create a event for John to call Sarah at 5 PM
                    </Text>
                    <Box
                      width={['100%', '54%', '64%', '64%']}
                      mt={['10%', '0px', '0px', '0px']}
                    >
                      <Image src='/colleagues15x.png' alt='colleagues' />
                    </Box>
                  </Flex>
                </Box>
                <Box
                  background='white'
                  borderRadius='6px'
                  boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                  padding={[
                    '20px 20px 0 20px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px'
                  ]}
                  mt='20px'
                >
                  <Flex
                    justifyContent='space-between'
                    direction={['column', 'row', 'row', 'row']}
                  >
                    <Box
                      width={['100%', '56%', '36%', '36%']}
                      mr={['0px', '2%', '10%', '10%']}
                      mb={['0px', '35px', '0px', '0px']}
                    >
                      <Heading
                        margin='0'
                        marginBottom='8px'
                        as='h3'
                        color='black'
                        fontSize={[
                          '1.75rem',
                          '2.1875rem',
                          '2.1875rem',
                          '2.1875rem'
                        ]}
                      >
                        For{' '}
                        <Box as='span' color='cardBlue'>
                          you
                        </Box>
                      </Heading>
                      <Text
                        fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                        opacity='0.6'
                      >
                        Remind yourself on Telegram about the start of the Apple
                        online keynote
                      </Text>
                    </Box>
                    <Box width={['100%', '40%', '40%', '40%']}>
                      <Image src='/you15x.png' alt='you' />
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                background='white'
                borderRadius='6px'
                boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                padding={[
                  '20px 20px 0 20px',
                  '15px 30px 0 30px',
                  '15px 30px 0 30px',
                  '15px 30px 0 30px'
                ]}
                width={['100%', '39%', '39%', '39%']}
                mt={['20px', '0px', '0px', '0px']}
              >
                <Heading
                  margin='0'
                  marginBottom='8px'
                  as='h3'
                  color='black'
                  fontSize={['1.75rem', '2.1875rem', '2.1875rem', '2.1875rem']}
                >
                  For your{' '}
                  <Box as='span' color='cardBlue'>
                    audience
                  </Box>
                </Heading>
                <Text
                  fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                  opacity='0.6'
                >
                  You can tell your followers about upcoming livestream
                </Text>
                <Image
                  mt='15px'
                  mb={['15px', '0px', '0px', '0px']}
                  padding={['0px', '10px', '10px', '0px']}
                  src='/audience15x.png'
                  alt='audience'
                />
              </Box>
            </Flex>
          </Box> */}
          {/* <Text textAlign='center' mt='43px'>
            And this is just the beginning! Use your imagination 🌈
          </Text> */}
        </Container>
      </Box>
      <Box id='event-ways'>
        <Container
          id='event-ways'
          maxW='1040px'
          paddingTop={['40px', '60px', '80px', '100px']}
          paddingBottom={['40px', '60px', '80px', '100px']}
        >
          <Box mb='60px'>
            <Heading
              textAlign='center'
              mb='15px'
              as='h3'
              fontSize={['2.25rem', '3rem', '3rem', '4rem']}
            >
              Receive meeting notifications{' '}
              <Box as='span' color='cardBlue'>
                anywhere
              </Box>
            </Heading>
            <Text
              textAlign='center'
              fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
            >
              People will receive notifications wherever they want. For example,
              in their favorite
              <br /> messenger or directly in the browser.{' '}
              <NextLink href='/coming'>
                <ChakraLink>
                  <Box as='span' color='purpleBlue'>
                    We are working on new integrations.
                  </Box>
                </ChakraLink>
              </NextLink>
            </Text>
          </Box>
          <Flex
            direction={['row', 'column', 'column', 'column']}
            justifyContent={['space-around', 'initial', 'initial', 'initial']}
          >
            <Flex
              direction={['column', 'row', 'row', 'row']}
              justifyContent='space-between'
            >
              <Flex
                background={['#FFFFFF', '#F4F4FE', '#F4F4FE', '#F4F4FE']}
                borderRadius='5px'
                alignItems='center'
                width='100%'
                direction={['column', 'row', 'row', 'row']}
                padding={['15px', '20px', '20px', '20px']}
                mb={['15px', '0', '0', '0']}
                justifyContent={['center', 'initial', 'initial', 'initial']}
              >
                <Box
                  bg='wayBlue'
                  width='74px'
                  height='74px'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderRadius='7px'
                  boxShadow='0px 4px 10px rgba(77, 110, 241, 0.1)'
                  mr={['0', '14px', '14px', '14px']}
                  mb={['8px', '0', '0', '0']}
                >
                  <Image
                    width='40px'
                    height='40px'
                    src='home_telegram.svg'
                    alt='telegram'
                  />
                </Box>
                <Text
                  fontSize={['0.9375rem', '1.25rem', '1.375rem', '1.375rem']}
                >
                  Telegram
                </Text>
              </Flex>
              <Flex
                padding={['15px', '20px', '20px', '20px']}
                mb={['15px', '0', '0', '0']}
                justifyContent={['center', 'initial', 'initial', 'initial']}
                background={['#FFFFFF', '#F4F4FE', '#F4F4FE', '#F4F4FE']}
                borderRadius='5px'
                alignItems='center'
                width='100%'
                marginRight={['0', '20px', '20px', '20px']}
                direction={['column', 'row', 'row', 'row']}
                marginLeft={['0', '20px', '20px', '20px']}
              >
                <Box
                  bg='wayBlue'
                  width='74px'
                  height='74px'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderRadius='7px'
                  boxShadow='0px 4px 10px rgba(77, 110, 241, 0.1)'
                  mr={['0', '14px', '14px', '14px']}
                  mb={['8px', '0', '0', '0']}
                >
                  <Image
                    width='40px'
                    height='40px'
                    src='/home_email.svg'
                    alt='Email'
                  />
                </Box>
                <Text
                  fontSize={['0.9375rem', '1.25rem', '1.375rem', '1.375rem']}
                >
                  Email
                </Text>
              </Flex>
              <Flex
                padding={['15px', '20px', '20px', '20px']}
                mb={['15px', '0', '0', '0']}
                justifyContent={['center', 'initial', 'initial', 'initial']}
                background={['#FFFFFF', '#F4F4FE', '#F4F4FE', '#F4F4FE']}
                borderRadius='5px'
                alignItems='center'
                direction={['column', 'row', 'row', 'row']}
                width='100%'
              >
                <Box
                  bg='wayBlue'
                  width='74px'
                  height='74px'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderRadius='7px'
                  boxShadow='0px 4px 10px rgba(77, 110, 241, 0.1)'
                  mr={['0', '14px', '14px', '14px']}
                  mb={['8px', '0', '0', '0']}
                >
                  <Box as={FaFacebookMessenger} size='40px' color='#ffffff' />
                </Box>
                <Text
                  fontSize={['0.9375rem', '1.25rem', '1.375rem', '1.375rem']}
                >
                  Messenger
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Box background='#F9F9FF'>
        <Container
          id='use-cases'
          maxW='1040px'
          paddingTop={['40px', '60px', '65px', '65px']}
          paddingBottom={['40px', '45px', '45px', '45px']}
        >
          <Flex
            alignItems='center'
            direction='column'
            justifyContent='center'
            mb='60px'
          >
            <Heading
              as='h2'
              fontSize={['2.25rem', '3rem', '3rem', '4rem']}
              textAlign={['center', 'center', 'center', 'center']}
              mb='12px'
            >
              Make notes during {' '}
              <Box as='span' color='cardBlue'>
                meetings
              </Box>
            </Heading>
            {/* <Text fontSize='1.25rem' textAlign='center'>
              We will send you the summary after the meeting ends
            </Text> */}
          </Flex>
          <Box>
            <Flex mt='20px' direction={['column', 'row', 'row', 'row']}>
              <Box
                width={['100%', '59%', '59%', '59%']}
                mr={['0px', '20px', '20px', '20px']}
              >
                <Box
                  background='white'
                  borderRadius='6px'
                  boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                  padding={[
                    '20px 20px 0 20px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px'
                  ]}
                >
                  <Heading
                    margin='0'
                    marginBottom='8px'
                    as='h3'
                    color='black'
                    fontSize={[
                      '1.75rem',
                      '2.1875rem',
                      '2.1875rem',
                      '2.1875rem'
                    ]}
                  >
                    Before{' '}
                    <Box as='span' color='cardBlue'>
                      meeting
                    </Box>
                  </Heading>
                  <Flex
                    justifyContent='space-between'
                    direction={['column', 'row', 'row', 'row']}
                  >
                    <Text
                      fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                      opacity='0.6'
                      width={['100%', '45%', '35%', '35%']}
                      mb={['0px', '35px', '0px', '0px']}
                      mr={['0px', '1%', '1%', '1%']}
                    >
                      You can write your notes before meeting is started.
                    </Text>
                    <Box
                      width={['100%', '54%', '64%', '64%']}
                      mt={['10%', '0px', '0px', '0px']}
                    >
                      <Image src='/colleagues15x.png' alt='colleagues' />
                    </Box>
                  </Flex>
                </Box>
                <Box
                  background='white'
                  borderRadius='6px'
                  boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                  padding={[
                    '20px 20px 0 20px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px',
                    '15px 30px 0 30px'
                  ]}
                  mt='20px'
                >
                  <Flex
                    justifyContent='space-between'
                    direction={['column', 'row', 'row', 'row']}
                  >
                    <Box
                      width={['100%', '56%', '36%', '36%']}
                      mr={['0px', '2%', '10%', '10%']}
                      mb={['0px', '35px', '0px', '0px']}
                    >
                      <Heading
                        margin='0'
                        marginBottom='8px'
                        as='h3'
                        color='black'
                        fontSize={[
                          '1.75rem',
                          '2.1875rem',
                          '2.1875rem',
                          '2.1875rem'
                        ]}
                      >
                        For{' '}
                        <Box as='span' color='cardBlue'>
                          you
                        </Box>
                      </Heading>
                      <Text
                        fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                        opacity='0.6'
                      >
                        Hapen collects and sends notes after the meeting is ended to attendees.
                      </Text>
                    </Box>
                    <Box width={['100%', '40%', '40%', '40%']}>
                      <Image src='/you15x.png' alt='you' />
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box
                background='white'
                borderRadius='6px'
                boxShadow='0px 4px 25px rgba(0, 0, 0, 0.03)'
                padding={[
                  '20px 20px 0 20px',
                  '15px 30px 0 30px',
                  '15px 30px 0 30px',
                  '15px 30px 0 30px'
                ]}
                width={['100%', '39%', '39%', '39%']}
                mt={['20px', '0px', '0px', '0px']}
              >
                <Heading
                  margin='0'
                  marginBottom='8px'
                  as='h3'
                  color='black'
                  fontSize={['1.75rem', '2.1875rem', '2.1875rem', '2.1875rem']}
                >
                  Notes stay {' '}
                  <Box as='span' color='cardBlue'>
                    available
                  </Box>{' '}
                  after meeting
                </Heading>
                <Text
                  fontSize={['1rem', '1.25rem', '1.25rem', '1.25rem']}
                  opacity='0.6'
                >
                  You can access them by meeting link
                </Text>
                <Image
                  mt='15px'
                  mb={['15px', '0px', '0px', '0px']}
                  padding={['0px', '10px', '10px', '0px']}
                  src='/audience15x.png'
                  alt='audience'
                />
              </Box>
            </Flex>
          </Box>
          {/* <Text textAlign='center' mt='43px'>
            And this is just the beginning! Use your imagination 🌈
          </Text> */}
        </Container>
      </Box>
      <Box>
        <Container
          maxW='initial'
          width='100%'
          paddingTop={['40px', '60px', '60px', '80px']}
          paddingBottom={['40px', '60px', '60px', '80px']}
        >
          <Heading
            as='h3'
            fontSize={['2.25rem', '3rem', '3rem', '4rem']}
            lineHeight='1'
            color='black'
            textAlign={['center', 'center', 'center', 'center']}
            mb='18px'
          >
            Get started
          </Heading>
          {/* <Text
            fontSize='1.25rem'
            textAlign={['left', 'center', 'center', 'center']}
          >
            Events from everyday life. Now have their own page.
          </Text> */}
          <Box>
            <Flex marginTop='40px' marginBottom='30px' justifyContent='center' flexWrap='wrap'>
              <Button
                onClick={() => {
                  setSelectedItem(0)
                }}
                fontSize='1.25rem'
                padding='10px 20px'
                height='50px'
                background={selectedItem === 0 ? '#4065FF' : '#000'} color='#fff' _hover={{
                  background: '#4065FF', color: '#fff'
                }} borderRadius='8px' margin='10px'
              >
                Choose your availablity
              </Button>
              <Button
                onClick={() => {
                  setSelectedItem(1)
                }}
                fontSize='1.25rem'
                padding='10px 20px'
                height='50px'
                background={selectedItem === 1 ? '#4065FF' : '#000'} color='#fff' _hover={{
                  background: '#4065FF', color: '#fff'
                }} borderRadius='7px' margin='10px'
              >
                Create event type page
              </Button>
              <Button
                onClick={() => {
                  setSelectedItem(2)
                }}
                fontSize='1.25rem'
                padding='10px 20px'
                height='50px'
                background={selectedItem === 2 ? '#4065FF' : '#000'} color='#fff' _hover={{
                  background: '#4065FF', color: '#fff'
                }} borderRadius='7px' margin='10px'
              >
                Share link
              </Button>
              <Button
                onClick={() => {
                  setSelectedItem(3)
                }}
                fontSize='1.25rem'
                padding='10px 20px'
                height='50px'
                background={selectedItem === 3 ? '#4065FF' : '#000'} color='#fff' _hover={{
                  background: '#4065FF', color: '#fff'
                }} borderRadius='7px' margin='10px'
              >
                Wait for a booked event
              </Button>
            </Flex>
          </Box>
          <Flex direction='row' position='relative' justifyContent='center'>
            <Box maxWidth='1240px'>
              <Carousel selectedItem={selectedItem} autoPlay={false} emulateTouch swipeable showIndicators={false} showStatus={false} showArrows={false} showThumbs={false}>
                <div>
                  <Image alt='availability' src='/static/availability.jpg' title='availability' />
                </div>
                <div>
                  <Image alt='type' src='/static/type.jpg' title='type' />
                </div>
                <div>
                  <Image alt='link' src='/static/link.jpg' title='link' />
                </div>
                <div>
                  <Image alt='booked' src='/static/booked.jpg' title='booked' />
                </div>
              </Carousel>
            </Box>
          </Flex>
          <Flex marginTop='80px' justifyContent='center'>
            <NextLink href='/dashboard' textDecoration='none'>
              <Button
                textDecoration='none'
                padding={['10px 20px', '10px 20px', '10px 40px', '10px 40px']}
                fontSize={['1.125rem', '1.125rem', '1.5rem', '1.5rem']}
                background='#4065FF'
                fontWeight='600'
                lineHeight='1.75rem'
                height={['48px', '48px', '70px', '70px']}
                color='#ffffff'
                borderRadius='6px'
                _hover={{ background: '#0031ff', textDecoration: 'none' }}
              >
                Create a booking page
              </Button>
            </NextLink>
          </Flex>
        </Container>
      </Box>
      <Box background='#F9F9FF'>
        <Container
          id='use-cases'
          maxW='1040px'
          paddingTop={['40px', '60px', '65px', '65px']}
          paddingBottom={['40px', '45px', '45px', '45px']}
        >
          <Heading
            as='h2'
            fontSize={['2.25rem', '3rem', '3rem', '3rem']}
            textAlign={['center', 'center', 'center', 'center']}
            mb='48px'
          >
            Read a post about{' '}
            <Box as='span' color='#4065FF'>
              latest update
            </Box>{' '}
            🤸‍♂️
          </Heading>
          <BlogItems isHome />
          <Flex justifyContent='center' mt='32px'>
            <a
              href='https://www.producthunt.com/posts/hapen?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-hapen'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=271811&theme=dark&period=daily'
                alt='Hapen - Create event pages with built-in reminders | Product Hunt'
                width='250'
                height='54'
              />
            </a>
          </Flex>
        </Container>
      </Box>
      <Box background='#4065FF'>
        <Container
          maxW='1040px'
          paddingTop={['40px', '60px', '80px', '80px']}
          paddingBottom={['20px', '20px', '20px', '20px']}
        >
          <Heading
            as='h3'
            fontSize={['2.25rem', '3rem', '3rem', '3rem']}
            lineHeight='1'
            color='white'
            textAlign='center'
            mb='18px'
          >
            Special offer 💌
          </Heading>
          <Text color='white' textAlign='center' fontSize='1.25rem'>
            Subscribe to our newsletter to get ready-made recipes
          </Text>
          <Text color='white' textAlign='center' fontSize='1.25rem'>
            for increasing your audience's engagement and attracting
          </Text>
          <Text color='white' textAlign='center' fontSize='1.25rem'>
            more people to your business's important events
          </Text>
          <Box>
            <Flex justifyContent='center' mt='32px'>
              <iframe
                src='https://hapen.substack.com/embed'
                width='600'
                height='120'
                style={{
                  border: 'none',
                  background: 'tranparent'
                }}
                frameBorder='0'
                scrolling='no'
              />
            </Flex>
          </Box>
          <Text
            textDecoration='underline'
            color='white'
            textAlign='center'
            mt='43px'
          >
            <NextLink href='/coming'>
              <ChakraLink>See upcoming features 💫</ChakraLink>
            </NextLink>
          </Text>
          <Flex justifyContent='center' mt='32px'>
            <NextLink href='/privacypolicy'>
              <ChakraLink color='white' fontSize='0.85rem' mr='8px' ml='8px'>
                Privacy Policy
              </ChakraLink>
            </NextLink>
            <NextLink href='/terms'>
              <ChakraLink color='white' fontSize='0.85rem' mr='8px' ml='8px'>
                Terms
              </ChakraLink>
            </NextLink>
          </Flex>
        </Container>
      </Box>
      <style>
        {`
        .showcase:hover .showcase-button {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all ease-in-out 0.4s;
        }

        .showcase:hover img {
          box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.2);
          transition: all ease-in-out 0.4s;
        }

        .showcase {
          transform: scale3d(1, 1, 1);
          transition: all ease-in-out 0.4s;
        }

        .showcase:hover {
          transform: scale3d(1.03, 1.03, 1.03);
          transition: all ease-in-out 0.4s;
        }

        .Toaster__manager-bottom {
          z-index: 100000 !important;
        }
      `}
      </style>
    </div>
  )
}
