import { useEffect } from 'react'
import theme from '../theme'

function useCookieConsent () {
  // CookieConsent
  useEffect(() => {
    if (window.cookieconsent) {
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#edeff5',
            text: '#838391'
          },
          button: {
            background: theme.colors.primary
          }
        }
      })
    }
  }, [])
}

export default useCookieConsent
